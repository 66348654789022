.Controls {
  position: absolute;
  bottom: 47px;
  left: 0px;
  right: 0px;
  padding: 0px 25px;
  height: 40px;
  display: flex;
  z-index: 5;
  transition: opacity 3s ease-in-out;
}

.Controls svg {
  fill: var(--main-color);
  cursor: pointer;
}

.Controls svg:hover {
  fill: var(--hover-color);
}

.Controls .range {
  flex-grow: 1;
}

.Controls #playPauseBtn {
  width: 50px;
  text-align: center;
  font-size: 10px;
  line-height: 40px;
  background: transparent;
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #ffffff;
  margin-left: -17.5px;
}

.Controls #playPauseBtn svg {
  width: 15px;
}

.Controls #seconds {
  width: 50px;
  font-size: 10px;
  text-align: right;
  line-height: 30px;
}

.Controls #range {
  position: relative;
  flex-grow: 1;
  height: 40px;
  display: flex;
  align-items: center;
}

.Controls #range .track {
  cursor: pointer;
  position: relative;
  width: 100%;
  border-radius: 8px;
  height: 6px;
}

.Controls #range .track:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  border-radius: 8px;
  background: #3f404b;
  height: 6px;
  opacity: 0.25;
}

.Controls #rangeThumb {
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -17px;
  border-radius: 20px;
  position: absolute;
  left: 0%;
}

.Controls #rangeThumb:after {
  content: "";
  display: block;
  position: absolute;
  left: 17px;
  top: 17px;
  width: 6px;
  height: 6px;
  background: var(--main-color);
  border-radius: 10px;
}

.Controls #rangeThumb:hover:after {
  background: var(--hover-color);
}

.Controls .rangeProgress {
  position: absolute;
  top: 0;
  left: 0;
  height: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
}
