footer {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -1.05px;
  z-index: 2;
  display: flex;
  padding: 15px 25px;
  background: transparent;
  transition: background 0.3s ease-in-out;
}

footer #buy-btn {
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-decoration: underline;
  letter-spacing: normal;
  border-radius: 30px;
  white-space: nowrap;
  text-transform: uppercase;
}

footer #buy-btn:hover {
  color: var(--hover-color);
}

footer svg {
  fill: var(--main-color);
}

footer .spacer {
  flex-grow: 1;
}

footer a {
  text-decoration: none;
}

footer a:hover svg {
  fill: var(--hover-color);
}

footer .social {
  margin-left: 10px;
  max-height: 30px;
  overflow: hidden;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .social a {
  height: 24px;
}

footer svg {
  margin-left: 10px;
}

/* Mobile */
@media (max-width: 700px) {
  footer a:hover {
    text-decoration: none;
  }

  footer .social {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  footer svg {
    margin-left: 10px;
  }
}

/* Mobile Smaller */
@media (max-width: 370px) {
  footer svg {
    margin-left: 5px;
  }
}

/* iPhone 5 and smaller */
@media only screen and (max-device-width: 320px) {
  h1,
  h2 {
    font-size: 25px;
    line-height: 22.5px;
  }

  footer svg {
    margin-top: 3px;
    margin-left: 3px;
  }
}
