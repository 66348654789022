:root {
  --main-color: #bf342f;
  --hover-color: #de2a21;
}

html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
}

body {
  background-color: #070a0d;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: var(--main-color);
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.dg.ac {
  z-index: 1000 !important;
}

canvas {
  image-rendering: pixelated;
}
