.App header {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  padding: 0px;
  margin: 0px;
  height: 98px;
  z-index: 2;
}

.App header h1 {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  top: 25px;
  left: 25px;
  margin: 0;
  font-weight: normal;
  font-size: 25px;
  line-height: 22px;
  letter-spacing: -1.05px;
  z-index: 2;
  text-transform: uppercase;
}

.App header h2 {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 20px;
  position: absolute;
  top: 25px;
  right: 25px;
  margin: 0;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -1.05px;
  z-index: 2;
  text-transform: uppercase;
}

.UIElements {
  position: absolute;
  top: env(safe-area-inset-top);
  left: env(safe-area-inset-left);
  right: env(safe-area-inset-right);
  bottom: env(safe-area-inset-bottom);
}

.StartButton {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 100px;
}
